import React from "react"
// import { Link } from "gatsby-link"
// import Layout from "../components/layout"

// import { FaHome, FaTags } from "react-icons/fa"

// import OwnLink from "../components/Link"
// import TagsList from "../components/TagsList"

// import "../css/tags.css"

export default function Tags() {
  // let list = []
  // const { posts, post, tag } = pageContext

  // Object.keys(posts).map((tagName) => list.push(tagName))

  // if (tag) {
  //   return (
  //     <Layout>
  //       hello
  //     </Layout>
  //   )
  // }
  return <div>hello</div>
}
